<template>
    <div class="flex flex-col w-full h-full">
        <div v-if="supplier"
             class="flex items-center text-xl font-bold text-gray-900 sm:text-3xl bg-gray-50 px-6 cursor-pointer transition ease-in hover:text-blue-500 duration-100 pt-6 lg:pt-0"
             @click="edit">
            {{ supplier }} <i class="fal fa-pen ml-2 text-lg"/>
        </div>
        <users :companyId="$store.getters['master/selectedSupplier']"/>
    </div>
</template>

<script>

import Users from "@/master/views/Home/company/Users";
import {find} from "lodash";
import {DETAIL_PANEL_KEYS} from "@/master/constants";

export default {
    name: "SupplierUsers",
    components: {
        'users': Users
    },
    computed: {
        supplier() {
            const selected = this.$store.getters['master/selectedSupplier'];
            const suppliers = this.$store.getters['master/suppliers'];
            const supplier = find(suppliers, s => s.id === selected)
            if (supplier) {
                return supplier.name
            }
            return null
        }
    },
    methods: {
        edit() {
            const detailPayload = {
                key: DETAIL_PANEL_KEYS.EDIT_SUPPLIER,
                id: this.$store.getters['master/selectedSupplier']
            }
            this.$store.commit('master/setDetail', detailPayload);
            this.$store.commit('master/openDetail');
        }
    }
}
</script>

<style scoped>
</style>